import React, { Component } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import Layout from '../components/Layout';
import ExternalLink from '../helpers/ExternalLink';
import * as Data from '../components/Data';

class Home extends Component {
    render() {
        return (
            <Layout>
                <Row>
                    <Col md className="pt-1 pb-4 d-flex align-items-center justify-content-center">
                        <Image src="https://www.gravatar.com/avatar/0bce1e07839bac61dace967af2787ac1?s=400&d=robohash&r=g" alt="Gravatar Profile Image" rounded fluid />
                    </Col>
                    <Col md>
                        <h1>Hello World!</h1>
                        <p>
                            Greetings! I'm Stephen, an avid technology enthusiast, and my journey has
                            been a lifelong exploration of all things technology, particularly in the
                            realm of software development. If you're curious to connect, my social media
                            links can be found conveniently at the bottom of each page.
                        </p>
                        <p>
                            My fascination with technology gravitates strongly towards .NET and the array
                            of Microsoft Technologies. When it comes to web technologies, my trusty companions
                            are C# and .NET - my go-to language and framework. I've also embraced the power
                            of NodeJS with Express, which I employ for crafting robust back-end applications.
                            Expanding my horizons, I'm currently immersing myself in Golang, intrigued by its
                            potential as a versatile alternative for web back-end development.
                        </p>
                        <p>
                            Among the millions of front-end frameworks available, Angular is my number one go-to
                            option. I use it in my professional career and personal projects. It is incredibly
                            versatile and powerful. As someone who loves strongly typed languages, Angular and
                            TypeScript go hand in hand. I do appreciate React for less complex websites and applications.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <p>
                        <ExternalLink title={"GitHub"} url={Data.GitHub} /> serves as my companion for source control across my personal projects.
                        Meanwhile, Azure DevOps takes the lead in my professional sphere, presenting a harmonious
                        blend of familiar features. In comparing the two, DevOps stands out with its comprehensive
                        project planning tools, outshining the offerings in GitLab. Both DevOps and GitLab excel in
                        simplifying CI/CD setup and monitoring, coupled with robust project planning capabilities.
                        It's hard to definitively crown one superior over the other; each possesses its unique strengths and trade-offs.
                    </p>
                    <p>
                        Please view my <ExternalLink title={"LinkedIn"} url={Data.LinkedIn} /> for my professional profile.
                        I keep all my working history, education, certificates, projects and other related information updated
                        there. Feel free to connect with me and say hi!
                    </p>
                    <p>
                        My website is built with <ExternalLink title={"React"} url={"https://react.dev/"} />
                        &nbsp;and hosted using&nbsp;<ExternalLink title={"Cloudflare Pages"} url={"https://pages.cloudflare.com/"} />.
                    </p>
                </Row>
            </Layout>
        )
    }
}

export default Home;
